import {Controller} from "@hotwired/stimulus";

// shout-out to: https://inclusive-components.design/cards/
export default class BlogCardController extends Controller {
  static targets = ['card'];

  cardTargetConnected(card) {
    // added here because if the JS fails, the clicking won't work, so a pointer would be weird
    card.style.cursor = 'pointer';
  }

  openBlogPost(event) {
    if (event.target.tagName.toLowerCase() !== 'a') {
      const { params: { linkId }} = event;
      const link = document.getElementById(linkId);
      if (!link) {
        console.error(`No link found for slug ${linkId}`);
        return;
      }

      link.click();
    }
  }
}

import { Controller } from "@hotwired/stimulus"
import emailjs from 'emailjs-com'

export default class extends Controller {
  static targets = ["button", "form"]
  static values = { currentTrack: String }

  connect() {
    this.hideAllForms()
    emailjs.init("3Bfy99nQJob6NgwtB") // Replace with your actual emailJS user ID
  }

  selectTrack(event) {
    const track = event.currentTarget.dataset.track
    this.currentTrackValue = track
    this.hideAllForms()
    this.showForm(track)
  }

  hideAllForms() {
    this.formTargets.forEach(form => form.classList.add("hidden"))
  }

  showForm(track) {
    const form = this.formTargets.find(form => form.dataset.track === track)
    if (form) {
      form.classList.remove("hidden")
    }
  }

  submitForm(event) {
    event.preventDefault()
    const form = event.currentTarget.closest('form')
    const track = this.currentTrackValue
    const email = form.querySelector(`input[name="${track}_email"]`).value
    const newsletter = form.querySelector(`input[name="${track}_newsletter"]`).checked

    if (!this.validateEmail(email)) {
      alert('Please enter a valid email address.')
      return
    }

    // Collect all input fields except email and checkbox
    const fields = Array.from(form.querySelectorAll('input'))
    const fieldData = fields.reduce((acc, field) => {
      if (field.type === 'checkbox') {
        if (field.checked) {
          acc[field.name] = true;
        }
      } else {
        acc[field.name] = field.value;
      }
      return acc;
    }, {});

    // const newsletter = form.querySelector(`input[name="${track}_newsletter"]`).checked

    const templateParams = {
      recipient: email,
      from_name: 'Railsworld 2024 Form',
      track: track,
      ...fieldData,
      newsletter: newsletter ? 'Yes' : 'No'
    }

    emailjs.send('service_qh2pjku', 'template_afwdkm9', templateParams)
      .then((response) => {
        console.log('Email sent successfully:', response)
        alert('Thank you for your submission!')
        this.clearAllForms()
        this.hideAllForms()
      }, (error) => {
        console.error('Failed to send email:', error)
        alert('Sorry, there was an error submitting your form. Please try again.')
      })
  }

  clearAllForms() {
    this.formTargets.forEach(form => {
      form.querySelectorAll('input:not([type="checkbox"])').forEach(input => input.value = '')
      form.querySelectorAll('input[type="checkbox"]').forEach(input => input.checked = false)
    })
  }

  validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }
}

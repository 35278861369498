import {Controller} from '@hotwired/stimulus';

// https://codepen.io/andybarefoot/pen/QMeZda
export default class extends Controller {
  static targets = ["item", "container"];

  connect() {
    window.addEventListener('load', this.adjustHeight);
    window.addEventListener('resize', this.adjustHeight);

    this.adjustHeight();
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustHeight);
  }

  adjustHeight() {
    const container = document.querySelector('.blog-index');
    const items = document.querySelectorAll('[data-height-target="item"]');

    for (const item of items) {
      const gridAutoRows = getComputedStyle(container).getPropertyValue('grid-auto-rows');
      const rowHeight = parseInt(gridAutoRows, 10);

      const gridRowGap = getComputedStyle(container).getPropertyValue('grid-row-gap');
      const rowGap = parseInt(gridRowGap, 10);

      const itemHeight = item.getBoundingClientRect().height;
      const rowSpan = Math.ceil(
        (itemHeight + rowGap)
        / (rowHeight + rowGap)
      );

      item.style.gridRowEnd = "span " + rowSpan;
    }
  }
}

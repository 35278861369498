import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["reveal"];
  HOW_FAR_FROM_TOP_TO_REVEAL_IN_PIXELS = 150;
  
  connect() {
    this.USER_PREFERS_REDUCED_MOTION = window.matchMedia("(prefers-reduced-motion: reduce)").matches
  } 
  
  reveal() {
    for (const currentReveal of this.revealTargets) {
      const windowHeight = window.innerHeight;
      const elementTop = currentReveal.getBoundingClientRect().top;
      const visibilityThreshold =
        windowHeight - this.HOW_FAR_FROM_TOP_TO_REVEAL_IN_PIXELS;
      if (this.USER_PREFERS_REDUCED_MOTION) {
        currentReveal.classList.add("active");
      } else if (elementTop < visibilityThreshold) {
        currentReveal.classList.add("active");
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class Top extends Controller {
  connect() {
    this.checkScrollPosition()
    window.addEventListener("scroll", this.checkScrollPosition.bind(this))
  }

  disconnect() {
    window.removeEventListener("scroll", this.checkScrollPosition.bind(this))
  }

  checkScrollPosition() {
    const backToTopButton = this.element

    if (window.scrollY > 100) {
      backToTopButton.classList.remove("hidden")
    } else {
      backToTopButton.classList.add("hidden")
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
}

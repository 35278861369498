import { Controller } from "@hotwired/stimulus"
const axios = require('axios')

export default class extends Controller {
  static targets = ["inputGroup", "submitButton", "successMessage"]

  initialize() {
    this.postUrl = "https://kontact.beflagrant.com/contacts.json";
  }

  handleSubmit(e) {
    e.preventDefault()
    const data = new FormData(this.element)
    this.disableForm()

    axios({
      method: "post",
      url: this.postUrl,
      data: data
    })
      .then(r => { this.handleSuccessfulKaseSubmission() })
      .catch(r => { this.handleFailedKaseSubmission(JSON.parse(r.response.data.errors)) })
  }

  handleFailedKaseSubmission(errors) {
    this.updateErrors(errors)
    this.enableForm()
  }

  handleSuccessfulKaseSubmission() {
    this.resetForm()
    this.displaySuccessMessage()
  }

  displaySuccessMessage() {
    this.submitButtonTarget.classList.replace("submitting", "submitted")
    this.submitButtonTarget.value = 'SENT'
    this.successMessageTarget.classList.remove('hidden')
  }

  resetForm() {
    this.removeErrorMessages()
    this.element.reset()
  }

  disableForm() {
    this.submitButtonTarget.setAttribute('disabled', true)
    this.submitButtonTarget.classList.add('submitting')
  }

  enableForm() {
    this.submitButtonTarget.removeAttribute('disabled')
    this.submitButtonTarget.classList.remove('submitting')
  }

  removeErrorMessages() {
    this.inputGroupTargets.forEach(inputGroup => {
      inputGroup.querySelector('.errorMessage').innerHTML = ""
    })
  }

  setErrorMssage(inputGroup, msg) {
    inputGroup.querySelector('.errorMessage').innerHTML = msg
  }

  updateErrors(errors) {
    this.removeErrorMessages()
    this.inputGroupTargets.forEach(group => {
      let type = group.dataset.type
      if (type in errors) {
        this.setErrorMssage(group, errors[type][0])
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus";

export default class DetailsEventController extends Controller {
  connect() {
    const hideDetailsOnMainClick = () => {
      this.element.open = undefined;

      document
        .querySelector("main")
        .addEventListener("click", hideDetailsOnMainClick);
    };

    const hideDetailsOnEscape = (event) => {
      if (event.key === "Escape") {
        this.element.open = undefined;
      }

      window.removeEventListener("keydown", hideDetailsOnEscape);
    };

    this.element.addEventListener("click", () => {
      window.addEventListener("keydown", hideDetailsOnEscape);
      document
        .querySelector("main")
        .addEventListener("click", hideDetailsOnMainClick);
    });
  }
}

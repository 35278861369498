import { Controller } from "@hotwired/stimulus";

export default class BannerController extends Controller {
  static KEY = '__FLAGRANT_WEB--RUBY_CONF_GUMROAD_BANNER_HIDE';

  connect() {
    // Hide if the banner has been closed
    if (this.readBannerClosed()) {
      this.hideBanner();
    }
  }

  close() {
    this.setBannerClosed();
    this.hideBanner();
  }

  hideBanner() {
    const bannerElement = document.getElementById('banner-id');
    bannerElement.className = 'hidden';
  }

  readBannerClosed() {
    return localStorage.getItem(BannerController.KEY) === "true";
  }

  setBannerClosed() {
    localStorage.setItem(BannerController.KEY, "true");
  }
}

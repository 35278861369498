import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ 'open', 'closed' ]
  static values = { threshold: 1024 }

  connect() {
    window.addEventListener('resize', this.handleResize.bind(this));
    this.previousWidth = window.innerWidth;

    const servicesToggle = document.getElementById('services-toggle');
    servicesToggle.addEventListener('toggle', this.toggleServicesSubnav.bind(this));
  }

  disconnect() {
    window.removeEventListener('resize', this.handleResize.bind(this));

    const servicesToggle = document.getElementById('services-toggle');
    servicesToggle.removeEventListener('toggle', this.toggleServicesSubnav.bind(this));
  }

  openMenu() {
    this.element.classList.add(this.openClass)
    this.element.classList.remove(this.closedClass)
    this.closeServicesSubnav()
  }
  
  closeMenu() {
    this.element.classList.remove(this.openClass)
    this.element.classList.add(this.closedClass)
    this.closeServicesSubnav()
  }
  
  toggleServicesSubnav() {
    const servicesSubnav = document.getElementById('services-subnav');
    const servicesToggle = document.getElementById('services-toggle');
    
    if (servicesToggle.open) {
      servicesSubnav.classList.remove('hidden');
    } else {
      servicesSubnav.classList.add('hidden');
    }
  }

  handleResize() {
    const windowWidth = window.innerWidth;

    if (windowWidth < this.thresholdValue) {
      if (this.previousWidth >= this.thresholdValue) {
        this.closeServicesSubnav();
      }
    } else {
      if (this.previousWidth <= this.thresholdValue) {
        this.closeMenu();
      }
    }

    this.previousWidth = windowWidth;
  }

  closeServicesSubnav() {
    const servicesToggle = document.getElementById('services-toggle');
    const servicesSubnav = document.getElementById('services-subnav');
    
    if (servicesToggle.open) {
      servicesToggle.open = false;
      servicesSubnav.classList.add('hidden');
    }
  }
}
